import React, { Component } from "react";


import Layout from "../components/layout"
import CatPagContent from "./category_content"

import { StaticQuery ,graphql} from "gatsby"
const objectGraphql = graphql`
{
  allStrapiNoticias(filter: {locale: {eq: "es-PE"}}) {
   edges {
     node {
      id
      titulo
       
      Tags {
        nombre
        id
      }
       
      Categorys {
        nombre
      }
      fecha
      min_descripcion
      descripcion
      imagen {
        url
      }
     }
   }
 }
}
`

class Category extends Component {

    render() {


        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <React.Fragment>
                        <Layout  nameTab='Categoria' props={this.props.props}>
                        <CatPagContent props={this.props.props} data={data}/>
                        </Layout>
                    </ React.Fragment>
                )}
            />
        )
    }
}

export default Category
